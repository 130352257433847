const Base= "https://gestordeproyectos-api.azurewebsites.net"
//const Base= "http://localhost:3001";

export const createAvance = async (datos) =>{
    try{
        const response = await fetch(Base+'/AvancesDesarollo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(datos)
        });
        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const result = await response.json();
        return result;
    }catch (error) {
        console.error('Error guardando el avance:', error);
        throw error;
    }
}

export async function getAllAvances(){
    try{
        const response = await fetch(Base+'/AvancesDesarollo');
        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }
        const result = await response.json();
        return result;
    }catch(error){
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function getAvanceById(id){
    try{
        const response = await fetch(Base+'/AvancesDesarollo/' + id);
        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }
        const result = await response.json();
        return result;
    }catch(error){
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function updateAvance(id, datos){
    try{
        const response = await fetch(Base+'/AvancesDesarollo/' + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(datos)
        });
        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }
        const result = await response.json();
        return result;
    }catch (error) {
        console.error('Error actualizando el avance:', error);
        throw error;
    }
}