import React, { useState, useMemo, useEffect } from 'react';
import { 
  Button, 
  TextField, 
  Typography, 
  Box, 
  Container, 
  Grid, 
  Select, 
  MenuItem, 
  InputLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel, 
  Checkbox, 
  Modal 
} from '@mui/material';
import { SendSolicitud } from '../APIS/Solicitudes';
import ParticlesComponent from '../Components/ParticlesComponent'; 

const RegistrarIdea = () => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // Estados del formulario
  const [formData, setFormData] = useState({
    idea: '',
    descripcion: '',
    un: '',
    clasificacion: [],
    impacto: '',
    nombre: '',
    correo: '',
    TieneProceso: '',
  });
  
  const [folio, setFolio] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // Datos estáticos
  const unidadesNegocio = [
    "ADMINISTRACIÓN",
    "ADOBE",
    "AUTODESK",
    "CENTRO DE DATOS HÍBRIDOS",
    "CENTRO ESPECIALIZADO DE SERVICIOS ADMINISTRADOS",
    "COMPUCLOUD",
    "DIRECCIÓN",
    "GESTIÓN DE SERVICIOS ADMINISTRADOS",
    "GESTIÓN DE TALENTO",
    "GOBIERNO",
    "INFRAESTRUCTURA",
    "MERCADOTECNIA",
    "MICROSOFT",
    "MONITOREO Y GESTIÓN REMOTA",
    "MOVILIDAD Y SEGURIDAD",
    "MULTIMARCA",
    "OPERACIONES",
    "SAI",
    "SERVICIOS TECNICOS Y LOGISTICA",
    "STAFF DIRECCIÓN",
    "TRANSFORMACIÓN DIGITAL"
  ];
  const impactos = [
    "Área", "Varias Áreas", "Toda la empresa", "Un proceso"
  ];
  
  const clasificaciones = [
    "Automatización", "Innovación", "IA", "RPA", 
    "Nuevo modulo en portal compucad"
  ];

  // Generar folio al cargar el componente
  useEffect(() => {
    const fechaRegistro = new Date().toISOString().slice(0, 10);
    setFolio(`CCADI${Math.floor(100 + Math.random() * 900)}-${fechaRegistro}`);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleClasificacionChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prev => {
      if (checked) {
        return {
          ...prev,
          clasificacion: [...prev.clasificacion, name]
        };
      } else {
        return {
          ...prev,
          clasificacion: prev.clasificacion.filter(item => item !== name)
        };
      }
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validar campos requeridos
    const { idea, descripcion, un, impacto, nombre, correo, clasificacion, TieneProceso } = formData;
    if (!idea || !descripcion || !un || !impacto || !nombre || !correo || clasificacion.length === 0 || !TieneProceso) {
      alert('Todos los campos son requeridos');
      return;
    }

    // Prevenir múltiples envíos
    if (isSubmitting) return;
    setIsSubmitting(true);

    // Mapeo de sponsors por unidad de negocio
    const sponsors = {
      "ADMINISTRACIÓN": "martha.vazquez@compucad.com.mx",
      "ADOBE": "hugo.hernandez@compucad.com.mx",
      "AUTODESK": "abraham.orozco@compucad.com.mx",
      "CENTRO DE DATOS HÍBRIDOS": "felipe.ochoa@compucad.com.mx", // Antes CDH
      "CENTRO ESPECIALIZADO DE SERVICIOS ADMINISTRADOS": "mauricio.islas@compucad.com.mx", // Nuevo - correo por defecto
      "COMPUCLOUD": "oswaldo.luna@compucloud.com.mx",
      "DIRECCIÓN": "juanc.gonzalez@compucad.com.mx",
      "GESTIÓN DE SERVICIOS ADMINISTRADOS": "mauricio.islas@compucad.com.mx", // Nuevo - correo por defecto
      "GESTIÓN DE TALENTO": "gricelda.santana@compucad.com.mx", // Nuevo - correo por defecto
      "GOBIERNO": "jaime.rodriguez@compucad.com.mx",
      "INFRAESTRUCTURA": "raul.camara@compucad.com.mx",
      "MERCADOTECNIA": "raul.benhumer@compucad.com.mx", // Antes MARKETING
      "MICROSOFT": "juan.panas@compucad.com.mx",
      "MONITOREO Y GESTIÓN REMOTA": "mauricio.islas@compucad.com.mx", // Nuevo - correo por defecto
      "MOVILIDAD Y SEGURIDAD": "fernando.jimenez@compucad.com.mx",
      "MULTIMARCA": "eugenia.angeles@compucad.com.mx", // Nuevo - correo por defecto
      "OPERACIONES": "ivone.ramirez@compucad.com.mx",
      "SAI": "fernando.jimenez@compucad.com.mx",
      "SERVICIOS TECNICOS Y LOGISTICA": "mauricio.islas@compucad.com.mx", // Nuevo - correo por defecto
      "STAFF DIRECCIÓN": "juanc.gonzalez@compucad.com.mx",
      "TRANSFORMACIÓN DIGITAL": "juan.panas@compucad.com.mx"
    };
    const sponsorEmail = sponsors[un] || 'Unidad no encontrada';
    const fechaRegistro = new Date().toISOString().slice(0, 10);

    // Preparar datos de la solicitud
    const solicitud = {
      Solicitud: {
        Correo: `${correo}@compucad.com.mx`,
        FechaRegistro: fechaRegistro,
        Idea: idea,
        TieneProceso: TieneProceso,
        Descripción: descripcion,
        UN: un,
        Clasificacion: clasificacion.join(', '),
        Impacto: impacto,
        Folio: folio,
        Status: "En revisión",
        Director: sponsorEmail
      },
      Solicitante: {
        Nombre: nombre,
        Correo: `${correo}@compucad.com.mx`,
        UN: un
      }
    };

    try {
      // Enviar solicitud
      await SendSolicitud(solicitud);
      
      // Enviar a Azure Logic App
      await fetch('https://prod-17.westus.logic.azure.com:443/workflows/f9ade3e0c955457d8347956e9338884b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=C3J6O3m-IGIo5gknFuYPuv3tDngOUgfnIF0mDQ6ZbS8', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(solicitud),
      });
console.log(solicitud);
      // Mostrar modal de éxito
      setOpenModal(true);
      
      // Limpiar formulario
      setFormData({
        idea: '',
        descripcion: '',
        un: '',
        clasificacion: [],
        impacto: '',
        nombre: '',
        correo: '',
        TieneProceso: '',
      });
      
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      alert('Error al enviar la solicitud');
    } finally {
      setIsSubmitting(false);
    }
  };

  const particlesComponentMemo = useMemo(() => <ParticlesComponent />, []);

  return (
    <div style={{ position: 'relative' }}>
      {particlesComponentMemo}
      
      <div style={{ position: 'relative', zIndex: 2 }}>
        <Container maxWidth="sm" sx={{
          mt: 0,
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(2px)',
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
        }}>
          <Box>
            <img 
              src="https://static.wixstatic.com/media/9c7b90_f4b51a4cfbf94766baae2af4cc2a3f02~mv2.png" 
              alt="logo" 
              style={{ 
                width: '100px', 
                height: '50px', 
                display: 'block', 
                marginLeft: 'auto', 
                marginRight: 'auto' 
              }} 
            />
            <Typography 
              variant="h4" 
              component="div" 
              sx={{ 
                textAlign: 'center', 
                fontWeight: 'bold', 
                marginTop: 4, 
                marginBottom: 4 
              }}
            >
              Estás a punto de unirte a la transformación digital
            </Typography>
          </Box>
          
          <Box sx={{ textAlign: 'center', mt: 2, mb: 4 }}>
            <Typography variant="subtitle1" sx={{ color: '#424242', marginBottom: 2 }}>
              Al registrar una idea ayudas con la mejora de los procesos, potenciando el talento tuyo y de todos los colaboradores.
            </Typography>
            <Typography variant="subtitle1" sx={{ color: '#424242', fontStyle: 'italic' }}>
              Aportar tu idea te tomará solo 5 minutos.
            </Typography>
            <br />
            <Typography variant="subtitle1" sx={{ color: 'red', fontStyle: 'italic' }}>
              IMPORTANTE: Describe tu idea de forma clara y concisa, recuerda que la información que proporciones será clave para la evaluación de tu idea.
            </Typography>
          </Box>

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="idea"
                  label="Idea"
                  name="idea"
                  autoComplete="idea"
                  value={formData.idea}
                  onChange={handleChange}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="descripcion"
                  label="Descripción"
                  name="descripcion"
                  multiline
                  rows={4}
                  value={formData.descripcion}
                  onChange={handleChange}
                />
              </Grid>
              
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="un-label">Unidad de Negocio</InputLabel>
                  <Select
                    labelId="un-label"
                    id="un"
                    name="un"
                    value={formData.un}
                    onChange={handleChange}
                    label="Unidad de Negocio"
                  >
                    {unidadesNegocio.map(unidad => (
                      <MenuItem key={unidad} value={unidad}>{unidad}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
        
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="impacto-label">Impacto</InputLabel>
                  <Select
                    labelId="impacto-label"
                    id="impacto"
                    name="impacto"
                    value={formData.impacto}
                    onChange={handleChange}
                    label="Impacto"
                  >
                    {impactos.map(impacto => (
                      <MenuItem key={impacto} value={impacto}>{impacto}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="proceso-label">
                    ¿Tu solicitud es parte de algún proceso documentado en Compucad?
                  </InputLabel>
                  <Select
                    labelId="proceso-label"
                    id="TieneProceso"
                    name="TieneProceso"
                    value={formData.TieneProceso}
                    onChange={handleChange}
                    label="¿Tu solicitud es parte de algún proceso documentado en Compucad?"
                  >
                    <MenuItem value="Si">Sí</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Si, pero requiere actualización">Sí, pero requiere actualización</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset" variant="standard">
                  <Typography component="legend">Clasificación</Typography>
                  <FormGroup>
                    {clasificaciones.map((clasif) => (
                      <FormControlLabel
                        key={clasif}
                        control={
                          <Checkbox
                            checked={formData.clasificacion.includes(clasif)}
                            onChange={handleClasificacionChange}
                            name={clasif}
                          />
                        }
                        label={clasif}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="nombre"
                  label="Nombre como apareces en Teams"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="body2">
                  Completa el correo, no incluyas el "@compucad.com.mx"
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    required
                    fullWidth
                    id="correo"
                    label="Primera parte del correo"
                    name="correo"
                    value={formData.correo}
                    onChange={handleChange}
                  />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    @compucad.com.mx
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Enviando...' : 'Enviar Solicitud'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Modal 
          open={openModal} 
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              ¡Gracias por tu aportación!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Tu idea ha sido registrada con éxito. Estás ayudando a impulsar la transformación digital de la empresa.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Folio de tu idea: {folio}
            </Typography>
            <Button 
              onClick={handleCloseModal} 
              sx={{ mt: 2 }}
              variant="contained"
            >
              Cerrar
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default RegistrarIdea;