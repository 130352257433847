import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button} from '@mui/material';
import { createAvance, getAllAvances } from '../APIS/AvanceDesarrollos';
const AvancesTabla = ({ codigo, nombre, Developer }) => {
    const [avances, setAvances] = useState([]);
    const [newAvance, setNewAvance] = useState({
        Codigo: codigo || '',
        Nombre_del_Proyecto: nombre || '',
        Developer: Developer || '',
        Avance_del_proyecto: 0,
        Detalles_del_Avance: '',
        Solicitud_de_Requerimiento: '',
        Comentarios: ''
    });
    
    useEffect(() => {
        const fetchAvances = async () => {
            if (!codigo) {
                console.error("El código es inválido");
                setAvances([]);
                return;
            }
            try {
                const response = await getAllAvances();    
                const avancesArray = Array.isArray(response) 
                    ? response 
                    : (response.data && Array.isArray(response.data) 
                        ? response.data 
                        : (response.avances && Array.isArray(response.avances) 
                            ? response.avances 
                            : []
                        )
                    );    
                try {
                    if (Array.isArray(avancesArray)) {
                        const avancesFiltrados = avancesArray.filter(avance => String(avance.Codigo).trim() === String(codigo).trim());
                        setAvances(avancesFiltrados);
                    } else {
                        throw new Error("No se pudo filtrar, avancesArray no es un arreglo válido");
                    }
                } catch (error) {
                    console.warn("Advertencia al filtrar los avances:", error.message);
                    setAvances([]); 
                }
            } catch (error) {
                console.error("Error al obtener los avances:", error);
                setAvances([]);
            }
        };
    
        fetchAvances();
    }, [codigo]);    

    const handleAddAvance = async () => {
        try {
            const response = await createAvance(newAvance);
            setAvances(prev => [...prev, response]);
            setNewAvance({
                Codigo: codigo || '',
                Nombre_del_Proyecto: nombre || '',
                Developer: Developer || '',
                Avance_del_proyecto: 0,
                Detalles_del_Avance: '',
                Solicitud_de_Requerimiento: '',
                Comentarios: ''
            });
        } catch (error) {
            console.error('Error al crear el avance:', error);
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell>Nombre del Proyecto</TableCell>
                        <TableCell>Desarrollador</TableCell>
                        <TableCell>Avance (%)</TableCell>
                        <TableCell>Detalles</TableCell>
                        <TableCell>Requerimientos</TableCell>
                        <TableCell>Comentarios</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {avances && Array.isArray(avances) ? (
                        avances.map(avance => (
                            <TableRow key={avance.id}>
                                <TableCell>{avance.Codigo}</TableCell>
                                <TableCell>{avance.Nombre_del_Proyecto}</TableCell>
                                <TableCell>{avance.Developer}</TableCell>
                                <TableCell>{avance.Avance_del_proyecto}%</TableCell>
                                <TableCell>
                                    <TextField
                                        value={avance.Detalles_del_Avance}
                                        multiline
                                        variant="standard" 
                                        disabled
                                        sx={{
                                            width: '100%', 
                                            '@media (max-width:600px)': { width: '100%' },
                                            '@media (min-width:600px)': { width: '80%' },
                                            '@media (min-width:960px)': { width: '70%' },
                                            '@media (min-width:1280px)': { width: '60%' },
                                        }}
                                        minRows={2}
                                        inputProps={{ style: { height: 'auto' } }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={avance.Solicitud_de_Requerimiento}
                                        multiline
                                        variant="standard" 
                                        disabled
                                        sx={{
                                            width: '100%',
                                            '@media (max-width:600px)': { width: '100%' },
                                            '@media (min-width:600px)': { width: '80%' },
                                            '@media (min-width:960px)': { width: '70%' },
                                            '@media (min-width:1280px)': { width: '60%' },
                                        }}
                                        minRows={2}
                                        inputProps={{ style: { height: 'auto' } }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={avance.Comentarios}
                                        multiline
                                        variant="standard" 
                                        disabled
                                        sx={{
                                            width: '100%',
                                            '@media (max-width:600px)': { width: '100%' },
                                            '@media (min-width:600px)': { width: '80%' },
                                            '@media (min-width:960px)': { width: '70%' },
                                            '@media (min-width:1280px)': { width: '60%' },
                                        }}
                                        minRows={2}
                                        inputProps={{ style: { height: 'auto' } }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={7}>No se han encontrado avances.</TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>{newAvance.Codigo}</TableCell>
                        <TableCell>{newAvance.Nombre_del_Proyecto}</TableCell>
                        <TableCell>{newAvance.Developer}</TableCell>
                        <TableCell>
                            <TextField
                                type="number"
                                value={newAvance.Avance_del_proyecto}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const parsedValue = parseFloat(value);
                                    if (!isNaN(parsedValue)) {
                                        setNewAvance((prev) => ({
                                            ...prev,
                                            Avance_del_proyecto: Math.max(0, Math.min(100, parsedValue)),
                                        }));
                                    }
                                }}
                                variant="standard"
                                fullWidth
                                minRows={3}
                                inputProps={{
                                    inputMode: 'numeric',
                                    step: "0.1",
                                    min: 0,
                                    max: 100
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        backgroundColor: "#1e1e1e", 
                                        color: "#00ff00", 
                                        borderRadius: 0.5,
                                        padding: "10px 12px",
                                        fontFamily: "'Courier New', monospace", 
                                        fontSize: "1rem",
                                        transition: "all 0.2s ease", 
                                        boxShadow: "0 0 10px rgba(0, 255, 0, 0.2)", 
                                        border: "1px solid #333", 
                                    },
                                    "& .MuiInputBase-root:hover": {
                                        boxShadow: "0 0 10px rgba(0, 255, 0, 0.4)",
                                    },
                                    "& .MuiInputBase-root:focus": {
                                        outline: "none", 
                                        boxShadow: "0 0 10px rgba(0, 255, 0, 0.8)", 
                                    },
                                    "& .MuiFormHelperText-root": {
                                        fontSize: "0.8rem",
                                        color: "#00ff00", 
                                    }
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                value={newAvance.Detalles_del_Avance}
                                onChange={(e) => setNewAvance(prev => ({ ...prev, Detalles_del_Avance: e.target.value }))} 
                                multiline
                                minRows={3}
                                fullWidth
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                value={newAvance.Solicitud_de_Requerimiento}
                                onChange={(e) => setNewAvance(prev => ({ ...prev, Solicitud_de_Requerimiento: e.target.value }))}
                                multiline
                                minRows={3}
                                fullWidth
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                value={newAvance.Comentarios}
                                onChange={(e) => setNewAvance(prev => ({ ...prev, Comentarios: e.target.value }))}
                                multiline
                                minRows={3}
                                fullWidth
                            />
                        </TableCell>
                        <TableCell>
                            <Button onClick={handleAddAvance}>Agregar</Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AvancesTabla;