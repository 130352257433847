import React, { useState, useEffect } from 'react';
import { Typography, Table,Autocomplete, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton, TextField, Button, Select, MenuItem, Box, Pagination, Grid, Paper,Link, FormControl, InputLabel,Modal} from '@mui/material';
import { CheckCircleOutline, HighlightOff, Chat, ArrowCircleRight} from '@mui/icons-material';
import MyDrawerComponent from '../Components/NavBar';
import { GetAllProyectos,SendTeamsAlertEncuesta,SendTeamsAlertProyecto } from '../APIS/proyectos';
import {GetSolicitudByid } from '../APIS/Solicitudes';
import {GetSolicitante } from '../APIS/Solicitudes';
import { GetAllMatriz } from '../APIS/MatrizPrioridad';
import AvisoLogin from '../Components/AvisoLogin';
import { useAuth0 } from "@auth0/auth0-react";

const Proyectos = () => {
  const Base="https://proyectos.td.compucad.com.mx";
  const [data, setData] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending
  const [estatusFilter, setEstatusFilter] = useState('');
  const [unidadNegocioFilter, setUnidadNegocioFilter] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [isCustomMessageEnabled, setIsCustomMessageEnabled] = useState(false);
  const LinkEncuestas="https://prod-141.westus.logic.azure.com:443/workflows/496d32f8d32340839644a96d802222c2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=dm31e95dfArfU8Xdce1XMzULSSq8wEdrByjLaputvrw"
  const { isAuthenticated } = useAuth0(); 
  const estatusOptions = ['En planeación','En proceso','En desarrollo', 'En productivo','Fase de pruebas', 'Descontinuado','Pausado','Por iniciar','Descartado' ];
  const unidadNegocioOptions = [
    'ADMINISTRACIÓN',
    'ADOBE',
    'AUTODESK',
    'CAD',
    'CDH',
    'COMPUCLOUD',
    'DIRECCIÓN',
    'GOBIERNO',
    'GT',
    'INFRAESTRUCTURA',
    'MICROSOFT',
    'MKT',
    'MOVILIDAD Y SEGURIDAD',
    'OPERACIONES',
    'SAI',
    'SERVICIOS PROFESIONALES',
    'TRANSFORMACIÓN DIGITAL',
  ]
  const [yearFilter, setYearFilter] = useState(''); 
  const [years, setYears] = useState([]); 
  const [desarrolladorFilter, setDesarrolladorFilter] = useState('');
  const [desarrolladores, setDesarrolladores] = useState([]); 
  const [matrizData, setMatrizData] = useState([]); 
  const [tamañoFilter, setTamañoFilter] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const handleFechaInicioChange = (event) => {
    setFechaInicio(event.target.value);
    setPage(1); // Reinicia la paginación cuando cambia la fecha
  };
  
  const handleFechaFinChange = (event) => {
    setFechaFin(event.target.value);
    setPage(1); // Reinicia la paginación cuando cambia la fecha
  };
  
useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const jsonData = await GetAllProyectos();
        
        setData(jsonData);


        const matrizData = await GetAllMatriz();
        setMatrizData(matrizData); 
        
        const uniqueYears = [...new Set(jsonData.map(item => new Date(item.FechaReal).getFullYear()))].sort((a, b) => b - a);
        setYears(uniqueYears);

        const uniqueDesarrolladores = [...new Set(jsonData.map(item => item.Desarrollador))];
        setDesarrolladores(uniqueDesarrolladores);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataFromApi();

  }, []);

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
    setPage(1); // Reset page when changing filters
  };

  const handleSortToggle = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  const handleEstatusFilterChange = (event) => {
    setEstatusFilter(event.target.value);
    setPage(1); // Reset page when changing filters
  };

  const handleUnidadNegocioFilterChange = (event) => {
    setUnidadNegocioFilter(event.target.value);
    setPage(1); // Reset page when changing filters
  };

  const handleYearFilterChange = (year) => {
    setYearFilter(year);
    setPage(1);
  };

  const handleDesarrolladorFilterChange = (value) => {
    setDesarrolladorFilter(value);
    setPage(1); // Reset page when changing filters
  };

  const filteredData = data ? data.filter(item => {
    const fechaProyecto = new Date(item.FechaReal); // Convierte la fecha del proyecto a tipo Date
    const matrizItem = matrizData.find(matriz => matriz.NombreProyecto === item.NombreProyecto);
    const tamaño = matrizItem ? matrizItem.tamaño : "Proyecto largo (Proceso nuevo)";
  
    return (
      (item.NombreProyecto?.toLowerCase().includes(filterText.toLowerCase()) || 
       item.Codigo?.toLowerCase().includes(filterText.toLowerCase())) &&
      (estatusFilter === '' || item.Status?.toLowerCase() === estatusFilter.toLowerCase()) &&
      (unidadNegocioFilter === '' || item.UN?.toLowerCase() === unidadNegocioFilter.toLowerCase()) &&
      (desarrolladorFilter === '' || item.Desarrollador?.toLowerCase().includes(desarrolladorFilter.toLowerCase())) &&      (tamañoFilter === '' || tamaño === tamañoFilter) &&
      (fechaInicio === '' || fechaFin === '' || 
       (fechaProyecto >= new Date(fechaInicio) && fechaProyecto <= new Date(fechaFin)))
    );
  }) : [];
  
  const sortedData = filteredData ? filteredData.sort((a, b) => {
    if (sortOrder === 'desc') {
      return a.Codigo.localeCompare(b.Codigo);
    } else {
      return b.Codigo.localeCompare(a.Codigo);
    }
  }) : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const indexOfLastRow = page * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

  const handleOpenModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === 'personalizado') {
      setIsCustomMessageEnabled(true);
    } else {
      setIsCustomMessageEnabled(false);
      setCustomMessage('');
    }
  };
        
  const handleSubmit = () => {
    console.log(selectedOption, customMessage);

if (selectedOption === 'encuesta') {

  if (!selectedProject.FechaReal) {
    selectedProject.FechaReal = new Date();
  }

  const fetchData = async () => {
    try {
      const solicitud = await GetSolicitudByid(selectedProject.IDSolicitud);
      const solicitante = await GetSolicitante(solicitud.IDSolicitante);
      
      let url=Base+'/contestar-encuesta/'+selectedProject.Codigo +'/'+solicitante.id;
      console.log(url);
      console.log("Link" +LinkEncuestas);
      console.log(selectedProject.Codigo);
      console.log(url);
      console.log(selectedProject.NombreProyecto);
      console.log(solicitante.Nombre);
      console.log(solicitud.Fecha);
      console.log(solicitante.Correo);
      console.log("No");
      const fechaTerminacion = new Date(selectedProject.FechaReal);
      fechaTerminacion.setDate(fechaTerminacion.getDate() + 30);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };

      SendTeamsAlertEncuesta(selectedProject.Codigo,url,selectedProject.NombreProyecto, selectedProject.Desarrollador, fechaTerminacion.toLocaleDateString("es-ES", options), solicitante.Correo, "No")
    
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Error al enviar la alerta');
      }
  }
  fetchData();

}
if (selectedOption === 'recordatorio') {
  const fetchData = async () => {
    try {
      const solicitud = await GetSolicitudByid(selectedProject.IDSolicitud);
      const solicitante = await GetSolicitante(solicitud.IDSolicitante);
      
      let url=Base+'/contestar-encuesta/'+selectedProject.Codigo +'/'+solicitante.id;
      console.log(url);
      console.log("Link" +LinkEncuestas);
      console.log(selectedProject.Codigo);
      console.log(url);
      console.log(selectedProject.NombreProyecto);
      console.log(solicitante.Nombre);
      console.log(solicitud.Fecha);
      console.log(solicitante.Correo);
      console.log("No");
      const fechaTerminacion = new Date(selectedProject.FechaReal);
      fechaTerminacion.setDate(fechaTerminacion.getDate() + 30);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };

      SendTeamsAlertEncuesta(selectedProject.Codigo,url,selectedProject.NombreProyecto, selectedProject.Desarrollador, fechaTerminacion.toLocaleDateString("es-ES", options), solicitante.Correo, "Si");
    
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();

}

if (selectedOption === 'En productivo') {
  const fetchData = async () => {
    try {
      const solicitud = await GetSolicitudByid(selectedProject.IDSolicitud);
      const solicitante = await GetSolicitante(solicitud.IDSolicitante);
      
      console.log(selectedProject.Codigo);
      console.log(selectedProject.NombreProyecto);
      console.log(solicitante.Nombre);
      console.log(solicitud.Fecha);
      console.log(solicitante.Correo);
      console.log("No");
      const fechaTerminacion = new Date(selectedProject.FechaReal);
      fechaTerminacion.setDate(fechaTerminacion.getDate() + 30);

      SendTeamsAlertProyecto(selectedProject.Codigo,selectedProject.NombreProyecto, selectedProject.Desarrollador, selectedProject.PM, solicitante.Correo, "En productivo");

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
}
if (selectedOption === 'Descontinuado') {
  const fetchData = async () => {
    try {
      const solicitud = await GetSolicitudByid(selectedProject.IDSolicitud);
      const solicitante = await GetSolicitante(solicitud.IDSolicitante);
      
    console.log(selectedProject.Codigo);
    console.log(selectedProject.NombreProyecto);
    console.log(solicitante.Nombre);
    console.log(solicitud.Fecha);
    console.log(solicitante.Correo);
    console.log("No");
    const fechaTerminacion = new Date(selectedProject.FechaReal);
    fechaTerminacion.setDate(fechaTerminacion.getDate() + 30);

    SendTeamsAlertProyecto(selectedProject.Codigo,selectedProject.NombreProyecto, selectedProject.Desarrollador, selectedProject.PM, solicitante.Correo, "Descontinuado");

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  fetchData();
}
if (selectedOption === 'Aprobado') {
  const fetchData = async () => {
    try {
      const solicitud = await GetSolicitudByid(selectedProject.IDSolicitud);
      const solicitante = await GetSolicitante(solicitud.IDSolicitante);
      
    console.log(selectedProject.Codigo);
    console.log(selectedProject.NombreProyecto);
    console.log(solicitante.Nombre);
    console.log(solicitud.Fecha);
    console.log(solicitante.Correo);
    console.log("No");
    const fechaTerminacion = new Date(selectedProject.FechaReal);
    fechaTerminacion.setDate(fechaTerminacion.getDate() + 30);

    SendTeamsAlertProyecto(selectedProject.Codigo,selectedProject.NombreProyecto, selectedProject.Desarrollador, selectedProject.PM, solicitante.Correo,  "Aprobado");


  
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  fetchData();
}

if (selectedOption === 'Rechazado') {
  const fetchData = async () => {
    try {
      const solicitud = await GetSolicitudByid(selectedProject.IDSolicitud);
      const solicitante = await GetSolicitante(solicitud.IDSolicitante);
      
      console.log(selectedProject.Codigo);
      console.log(selectedProject.NombreProyecto);
      console.log(solicitante.Nombre);
      console.log(solicitud.Fecha);
      console.log(solicitante.Correo);
      console.log("No");
      const fechaTerminacion = new Date(selectedProject.FechaReal);
      fechaTerminacion.setDate(fechaTerminacion.getDate() + 30);

      SendTeamsAlertProyecto(selectedProject.Codigo,selectedProject.NombreProyecto, selectedProject.Desarrollador, selectedProject.PM, solicitante.Correo, "Rechazado");

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
}


if (selectedOption === 'Pausado') {
  const fetchData = async () => {
    try {
      const solicitud = await GetSolicitudByid(selectedProject.IDSolicitud);
      const solicitante = await GetSolicitante(solicitud.IDSolicitante);
      
    console.log(selectedProject.Codigo);
    console.log(selectedProject.NombreProyecto);
    console.log(solicitante.Nombre);
    console.log(solicitud.Fecha);
    console.log(solicitante.Correo);
    console.log("No");
    const fechaTerminacion = new Date(selectedProject.FechaReal);
    fechaTerminacion.setDate(fechaTerminacion.getDate() + 30);

    SendTeamsAlertProyecto(selectedProject.Codigo,selectedProject.NombreProyecto, selectedProject.Desarrollador, selectedProject.PM, solicitante.Correo,  "Pausado");

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  fetchData();
}

    handleCloseModal(); 
    setSelectedOption('');
    setCustomMessage('');
    setIsCustomMessageEnabled(false);
  };
  const desarrolladoresUnicos = [...new Set((data || []).map(item => item.Desarrollador?.toLowerCase()).filter(Boolean))];

  return (

    <div>
      {isAuthenticated ? (
        <div>
          <MyDrawerComponent pageName="Proyectos" selectedMenuItem="Proyectos" />
          <br />
          <div style={{ maxWidth: '1500px', margin: '0 auto', padding: '20px' }}> 
          <Grid container spacing={2} alignItems="center">
  <Grid item xs={12} md={6} lg={3}>
    <TextField
      label="Filtrar por Nombre o Código"
      variant="outlined"
      value={filterText}
      onChange={handleFilterChange}
      fullWidth
    />
  </Grid>

  <Grid item xs={12} md={6} lg={3}>
    <Select
      value={estatusFilter}
      onChange={handleEstatusFilterChange}
      displayEmpty
      variant="outlined"
      fullWidth
    >
      <MenuItem value="" style={{ width: '100%' }}>Filtrar por Estatus</MenuItem>
      {estatusOptions.map((estatus, index) => (
        <MenuItem key={index} value={estatus} style={{ width: '100%' }}>{estatus}</MenuItem>
      ))}
    </Select>
  </Grid>

  <Grid item xs={12} md={6} lg={3}>
    <Select
      value={unidadNegocioFilter}
      onChange={handleUnidadNegocioFilterChange}
      displayEmpty
      variant="outlined"
      fullWidth
    >
      <MenuItem value="" style={{ width: '100%' }}>Filtrar por Unidad de Negocio</MenuItem>
      {unidadNegocioOptions.map((unidadNegocio, index) => (
        <MenuItem key={index} value={unidadNegocio} style={{ width: '100%' }}>{unidadNegocio}</MenuItem>
      ))}
    </Select>
  </Grid>

  <Grid item xs={12} md={6} lg={3}>
  <Autocomplete
    options={desarrolladoresUnicos}
    value={desarrolladorFilter}
    onChange={(event, newValue) => {
      setDesarrolladorFilter(newValue || ""); // Si se borra, establecer vacío
      setPage(1);
    }}
    renderInput={(params) => (
      <TextField {...params} label="Filtrar por Desarrollador" variant="outlined" fullWidth />
    )}
  />
</Grid>
  <Grid item xs={12} md={6} lg={3}>
    <Select
      value={tamañoFilter}
      onChange={(event) => {
        setTamañoFilter(event.target.value);
        setPage(1);
      }}
      displayEmpty
      variant="outlined"
      fullWidth
    >
      <MenuItem value="" style={{ width: '100%' }}>Filtrar por Tamaño</MenuItem>
      {Array.from(new Set(matrizData.map(item => item.tamaño))).map((tamaño, index) => (
        <MenuItem key={index} value={tamaño} style={{ width: '80%' }}>{tamaño}</MenuItem>
      ))}
    </Select>
  </Grid>

  {/* Filtro de Rango de Fechas, ocupa el mismo espacio que dos filtros (lg=6) */}
  <Grid item xs={12} md={6} lg={6}>
    <Box sx={{ 
      display: 'flex', 
      flexWrap: 'nowrap', 
      gap: 2, 
      padding: '10px', 
      border: '1px solid #ccc', 
      borderRadius: '8px', 
      backgroundColor: '#f9f9f9',
      alignItems: 'center',
      justifyContent: { xs: 'center', md: 'flex-start' },
      width: '100%'
    }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0067A0' }}>
        Rango de Fechas:
      </Typography>

      <TextField
        type="date"
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
        value={fechaInicio}
        onChange={handleFechaInicioChange}
        sx={{ width: { xs: '100%', sm: '140px' } }}
      />

      <Typography variant="body2" sx={{ fontWeight: 'bold' }}> - </Typography>

      <TextField
        type="date"
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
        value={fechaFin}
        onChange={handleFechaFinChange}
        sx={{ width: { xs: '100%', sm: '140px' } }}
      />

      <Button 
        variant="outlined" 
        size="small" 
        onClick={() => { setFechaInicio(''); setFechaFin(''); setPage(1); }}
        sx={{ minWidth: '40px', padding: '5px', borderColor: '#0067A0', color: '#0067A0' }}
      >
        Borrar
      </Button>
    </Box>
  </Grid>

  <Grid item xs={12} md={6} lg={3} style={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={handleSortToggle}
                  fullWidth
                  style={{ color: '#fff', backgroundColor: '#1976d2', borderColor: '#1976d2',maxWidth: '80%'}}
                >
                  {sortOrder === 'asc' ? 'Código (Ascendente)' : 'Código (Descendente)'}
                </Button>
              </Grid>
</Grid>

            
            <br />
            {currentRows.length > 0 ? (
              <TableContainer style={{ maxWidth: '100%', overflowX: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#0067A0', color: "#ffffff" }}>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Código</TableCell>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Nombre del Proyecto</TableCell>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold'}}>Desarrolladores</TableCell>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold'}}>Tamaño del Proyecto</TableCell> {/* Nueva columna */}
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Estatus</TableCell>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Avance</TableCell>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Encuestado</TableCell>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>PostMortem</TableCell>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Mensaje</TableCell>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Fecha Liberación</TableCell>
                    <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Detalles</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentRows.map((item, index) => {
                    const matrizItem = matrizData.find(matriz => matriz.NombreProyecto === item.NombreProyecto);
                    const tamaño = matrizItem ? matrizItem.tamaño : "Proyecto largo (Proceso nuevo)"; 

                    return (
                      <TableRow
                        key={item.id}
                        style={{ backgroundColor: index % 2 === 0 ? '#e3f2fd' : '#ffffff' }}
                      >
                        <TableCell align="center">{item.Codigo}</TableCell>
                        <TableCell align="center">{item.NombreProyecto}</TableCell>
                        <TableCell align="center" style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.Desarrollador}</TableCell>
                        <TableCell align="center" style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{tamaño}</TableCell>
                        <TableCell align="center">{item.Status}</TableCell>
                        <TableCell align="center">{item.Avance}%</TableCell>
                        <TableCell align="center">
                          {item.Encuestado ? (
                            <Link href={`/detalles-encuesta/${item.Codigo}`}>
                              <CheckCircleOutline style={{ color: 'green' }} />
                            </Link>
                          ) : (
                            <HighlightOff style={{ color: 'gray' }} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {item.PostMortem ? (
                            <Link href={`/post-mortem/${item.Codigo}`}>
                              <CheckCircleOutline style={{ color: 'green' }} />
                            </Link>
                          ) : (
                            <HighlightOff style={{ color: 'gray' }} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => handleOpenModal(item)}>
                            <Chat />
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
  {new Date(item.FechaReal).getFullYear() < 2010 
    ? "Sin fecha" 
    : new Date(item.FechaReal).toLocaleDateString("es-ES")}
</TableCell>
                        <TableCell align="center">
                          <Link href={`/detalles-proyecto/${item.Codigo}`}>
                            <IconButton>
                              <ArrowCircleRight />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>                                  
            ) : (
              <Box mt={4} display="flex" justifyContent="center" alignItems="center">
                <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white', color: 'gray', textAlign: 'center' }}>
                  <Typography variant="h6">No se encontraron proyectos que coincidan con el filtro.</Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img 
                      src="https://images.vexels.com/media/users/3/152639/isolated/preview/506b575739e90613428cdb399175e2c8-dibujos-animados-de-astronauta-espacial.png" 
                      alt="404" 
                      style={{ width: '200px', height: '200px' }}
                    />
                  </div>
                </Paper>
              </Box>
            )}
            <Box display="flex" justifyContent="center" marginTop="20px">
              <Pagination
                count={Math.ceil(sortedData.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
              />
            </Box>
            <Box 
              sx={{
                mt: 4, 
                py: 2, 
                px: 2, 
                backgroundColor: '#f5f5f5', 
                borderTop: '1px solid #e0e0e0',
                borderRadius: '8px',
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                bottom: 0,
                width: '99%'
              }}
            >
              <Typography variant="body2" color="textSecondary" align="center">
                Con ❤️ del equipo de TD para Compucad 🚀
              </Typography>
            </Box>
          </div>
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              backdropFilter: 'blur(10px)', // Estilo cristal
              border: '1px solid #0067A0', // Color de borde
              boxShadow: 24,
              borderRadius: 2, // Bordes redondeados
              p: 4,
            }}>
              <Typography id="modal-title" variant="h6" component="h2" color="#0067A0" fontWeight="bold">
                ¿Qué tipo de alerta deseas enviar?
              </Typography>
              <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                <InputLabel id="action-selector-label">Tipo</InputLabel>
                <Select
                  labelId="action-selector-label"
                  id="action-selector"
                  value={selectedOption}
                  label="Acción"
                  onChange={handleSelectChange}
                  style={{width:'100%'}}
                >
                  <MenuItem style={{width:'100%'}} value="recordatorio">Enviar recordatorio de encuesta</MenuItem>
                  <MenuItem style={{width:'100%'}} value="encuesta">Enviar Encuesta</MenuItem>
                </Select>
              </FormControl>
              {(isCustomMessageEnabled || selectedOption === 'personalizado') && (
                <TextField
                  fullWidth
                  id="custom-message"
                  label="Mensaje Personalizado"
                  multiline
                  rows={4}
                  value={customMessage}
                  onChange={(e) => setCustomMessage(e.target.value)}
                  sx={{ mb: 2 }}
                  required={selectedOption === 'personalizado'}
                />
              )}
              <Button onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#0067A0', '&:hover': { backgroundColor: '#004c7a' }, color: '#ffffff' }}>
                Enviar
              </Button>
            </Box>
          </Modal>
        </div>
      ) : (
        <div>
          <AvisoLogin />
        </div>
      )}
    </div>
  );
};

export default Proyectos;